var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Card',{attrs:{"title":_vm.detail.id ? '修改许可证' : '创建许可证'}},[_c('div',{staticStyle:{"padding-top":"24px"}},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 18 },"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"许可证编号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'number',
                {
                  initialValue: _vm.detail.number,
                  rules: [{ required: true, message: '请输入' }],
                },
              ]),expression:"[\n                'number',\n                {\n                  initialValue: detail.number,\n                  rules: [{ required: true, message: '请输入' }],\n                },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'actionType',
                {
                  initialValue: _vm.type,
                  rules: [{ required: true, message: '请选择' }],
                },
              ]),expression:"[\n                'actionType',\n                {\n                  initialValue: type,\n                  rules: [{ required: true, message: '请选择' }],\n                },\n              ]"}],staticStyle:{"width":"100%"}},_vm._l(([{
                  name: '申领',
                  value: '申领'
                }, {
                  name: '重新申请',
                  value: '重新申请',
                }, {
                  name: '变更',
                  value: '变更'
                }]),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"排水户"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'pid',
                {
                  initialValue: _vm.detail.main ? _vm.detail.main.id : undefined,

                  rules: [{ required: true, message: '请选择' }],
                },
              ]),expression:"[\n                'pid',\n                {\n                  initialValue: detail.main ? detail.main.id : undefined,\n\n                  rules: [{ required: true, message: '请选择' }],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"show-search":"","filter-option":_vm.$selectFilterOption}},_vm._l((_vm.list),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"发证日期"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'publishDate',
              {
                initialValue: _vm.detail.publishDate ? _vm.moment(_vm.detail.publishDate) : undefined,
              }
            ]),expression:"[\n              'publishDate',\n              {\n                initialValue: detail.publishDate ? moment(detail.publishDate) : undefined,\n              }\n            ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":""}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"发证机关"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'publishGov',
                {
                  initialValue: _vm.detail.publishGov
                }
              ]),expression:"[\n                'publishGov',\n                {\n                  initialValue: detail.publishGov\n                }\n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"有效期"}},[_c('a-range-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'validDate',
                {
                  initialValue: _vm.detail.validDateFrom ? [_vm.moment(_vm.detail.validDateFrom), _vm.moment(_vm.detail.validDateTo)] : undefined
                }
              ]),expression:"[\n                'validDate',\n                {\n                  initialValue: detail.validDateFrom ? [moment(detail.validDateFrom), moment(detail.validDateTo)] : undefined\n                }\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":[]}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"排放说明","label-col":{ span: 3 },"wrapper-col":{ span: 21 }}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'remark',        {
                  initialValue: _vm.detail.remark,
                  rules: [{ required: true, message: '请输入' }],
                },
              ]),expression:"[\n                'remark',        {\n                  initialValue: detail.remark,\n                  rules: [{ required: true, message: '请输入' }],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"auto-size":{ minRows: 6 }}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"许可证文件","label-col":{ span: 3 },"wrapper-col":{ span: 21 }}},[_c('input',{ref:"upload",staticStyle:{"display":"none"},attrs:{"type":"file"},on:{"change":_vm.onChange}}),_c('a-space',[_c('a-button',{attrs:{"loading":_vm.uploading},on:{"click":_vm.upload}},[_vm._v(_vm._s(_vm.path ? '重新上传' : '上传'))]),(_vm.path)?_c('a',{attrs:{"href":_vm.path.indexOf('http') === 0 ? _vm.path : 'https://' + 
                _vm.path,"target":"_blank"}},[_vm._v("查看")]):_vm._e()],1)],1)],1)],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{on:{"click":_vm.cancel}},[_vm._v("关闭")]),_c('a-button',{attrs:{"htmlType":"submit","type":"primary"}},[_vm._v("保存")])],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }