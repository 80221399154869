<template>
  <Card :title=" detail.id ? '修改许可证' : '创建许可证'">
    <div style="padding-top:24px">
      <a-form
        :form="form"
        @submit="handleSubmit"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
        :colon="false"
      >
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="许可证编号">
              <a-input
                v-decorator="[
                  'number',
                  {
                    initialValue: detail.number,
                    rules: [{ required: true, message: '请输入' }],
                  },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="类型">

              <a-select style="width: 100%" 
              v-decorator="[
                  'actionType',
                  {
                    initialValue: type,
                    rules: [{ required: true, message: '请选择' }],
                  },
                ]"
              >
                <a-select-option
                  v-for="item in [{
                    name: '申领',
                    value: '申领'
                  }, {
                    name: '重新申请',
                    value: '重新申请',
                  }, {
                    name: '变更',
                    value: '变更'
                  }]"
                  :key="item.value"
                  :value="item.value"
                >{{ item.name }}</a-select-option>
              </a-select>
           
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="排水户">

              <a-select style="width: 100%" 
              show-search
                :filter-option="$selectFilterOption"
              v-decorator="[
                  'pid',
                  {
                    initialValue: detail.main ? detail.main.id : undefined,

                    rules: [{ required: true, message: '请选择' }],
                  },
                ]"
              >
                <a-select-option
                  v-for="item in list"
                  :key="item.id"
                  :value="item.id"
                >{{ item.name }}</a-select-option>
              </a-select>
           
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="发证日期">

              <a-date-picker
              style="width: 100%"
              placeholder=""
              v-decorator="[
                'publishDate',
                {
                  initialValue: detail.publishDate ? moment(detail.publishDate) : undefined,
                }
              ]"
            />
              

            </a-form-item>
          </a-col>

          
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="发证机关">
              <a-input
                v-decorator="[
                  'publishGov',
                  {
                    initialValue: detail.publishGov
                  }
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="有效期">
              <a-range-picker
              style="width: 100%"
              :placeholder="[]"
                v-decorator="[
                  'validDate',
                  {
                    initialValue: detail.validDateFrom ? [moment(detail.validDateFrom), moment(detail.validDateTo)] : undefined
                  }
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="排放说明"
            :label-col="{ span: 3 }"
        :wrapper-col="{ span: 21 }"
            >
              <a-textarea
              style="width: 100%"
              :auto-size="{ minRows: 6 }"
                v-decorator="[
                  'remark',        {
                    initialValue: detail.remark,
                    rules: [{ required: true, message: '请输入' }],
                  },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="许可证文件"
            :label-col="{ span: 3 }"
        :wrapper-col="{ span: 21 }"
            >

            <input type="file" ref="upload" @change="onChange" style="display: none" />
            <a-space>
              <a-button @click="upload" :loading='uploading'>{{ path ? '重新上传' : '上传'}}</a-button>
              <a v-if="path" :href="
                 path.indexOf('http') === 0 ? path : 'https://' + 
                  path" target="_blank">查看</a>
            </a-space>

            </a-form-item>
          </a-col>

        </a-row>

        <div class="center">
          <a-space>
            <a-button @click="cancel">关闭</a-button>
            <a-button htmlType="submit" type="primary">保存</a-button>
          </a-space>
        </div>
      </a-form>
    </div>
  </Card>
</template>

<script>
import { upload } from "@/api/upload";
import request from "@/api/request";
import moment from "moment";

function add(data) {
  return request({
    url: "/model-analysis/discharger/license/save",
    method: "post",
    data,
  });
}

function edit(data) {
  return request({
    url: "/model-analysis/discharger/license/modify",
    method: "post",
    data,
  });
}

function fetchDetail(params) {
  return request({
    url: "/model-analysis/discharger/license/query/" + params.id,
  });
}

export default {
  data() {
    return {
      form: this.$form.createForm(this),
      list: [],
      path: '',
      uploading: false,
      detail: {},
      type: undefined,
    };
  },

  mounted() {
    request({
        url: '/model-analysis/discharger/list',
        method: "post",
        data: {
          pageNum: 1,
          pageSize: 999,
        },
      })
        .then((res) => {
          if (res && Array.isArray(res.list) && res.list.length > 0) {
            this.list = Object.freeze(res.list);
          }
        })


        const { id } = this.$route.query;

        if (id) {
          fetchDetail({
          id
        }).then(res => {
          if (Array.isArray(res.histories)) {
            const obj =  res.histories.find(item => item.number === res.number)
            this.type = obj ? obj.type : undefined;
          }
          this.detail = res || {}
          this.path = res.licenseFile || ''
        })

        }

  },  

  methods: {
    moment,
    upload() {
      this.$refs.upload.click();
    },
    onChange(e) {
      const files = e.target.files;
      if (files.length === 0) {
        return;
      }

      const data = new FormData();
      data.append("files", files[0]);

      this.uploading = true;
      upload(data)
        .then((res) => {
          console.log("upload", res);
          if (Array.isArray(res)) {
            const path = res[0].completePath
            this.path = path;
            this.$message.success('上传成功')
          }
        })
        .finally(() => {
          this.uploading = false;
          e.target.value = null;
        });

    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {

          const { publishDate, validDate} = values;
          let validDateFrom, validDateTo;
          if (validDate) {
            validDateFrom = validDate[0].format("YYYY-MM-DD")
            validDateTo = validDate[1].format("YYYY-MM-DD")
          }

          let task = add;
          if (this.detail.id) {
            task = edit;
          }

          task({
            ...this.detail,
            ...values,
            publishDate: publishDate ? publishDate.format("YYYY-MM-DD") : undefined,
            validDateFrom,
            validDateTo,
            licenseFile: this.path,
          }).then(() => {
            this.cancel();
          });
        }
      });
    },

    cancel() {
      this.$router.go(-1);
    },
  },
};
</script>